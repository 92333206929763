<template>
  <table-view
    :total='total'
    :query-info.sync='queryInfo'
    @reload-table='renderTable'
  >
    <template #header>
      <div class='header-button'>
        <el-button v-permission="['dorm:room:type:edit']" type='primary' size='small' @click='showDialog()'
        >添加宿舍类型
        </el-button>
      </div>
    </template>
    <!-- body -->
    <el-table :data='tableData' style='width: 100%' border stripe>
      <el-table-column type='index' label='ID' width='50' />
      <el-table-column prop='roomTypeName' label='宿舍类型' width='150' />
      <el-table-column prop='peopleAmount' label='可住人数' width='150' />
      <el-table-column prop='price' label='单价/元' width='150' />
      <el-table-column prop='imgUrl' label='宿舍布局图' width='150'>
        <template v-slot='{row}'>
          <div class='image'>
            <el-image
              style='width: 100%; height: auto'
              :src="$fileUrl + '' + row.imgUrl"
              :preview-src-list="[$fileUrl + '' + row.imgUrl]"
              fit='contain' />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop='remarks' label='备注' />
      <el-table-column label='操作' width='200' fixed='right'>
        <template v-slot='{row}'>
          <el-button
            v-permission="['dorm:room:type:edit']"
            type='primary'
            size='mini'
            @click='showDialog( row.id)'
          >编辑
          </el-button>
          <el-button
            v-permission="['dorm:room:type:del']"
            size='mini'
            type='danger'
            @click='del(deleteDormRoomType,row.id,`宿舍类型${row.roomTypeName}`)'
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <dorm-type-dialog ref='dialogRef' :visible.sync='visibleDialog' v-if='visibleDialog' @on-close='renderTable' />
  </table-view>
</template>

<script>
import { getDormRoomTypeList, deleteDormRoomType } from '@/api/dorm'
import dormTypeDialog from './component/dormTypeDialog.vue'
import tableView from '@/vue/mixins/table-view'

export default {
  components: {
    dormTypeDialog
  },
  mixins: [tableView],
  data () {
    return {}
  },
  mounted () {
    this.renderTable()
  },
  methods: {
    deleteDormRoomType, // 删除api
    // 获取宿舍楼列表
    renderTable () {
      getDormRoomTypeList(this.queryInfo).then((res) => {
        this.tableData = res.data.list.sort(
          (a, b) => a.peopleAmount - b.peopleAmount
        )
        this.total = res.data.total
      })
    }
  }
}
</script>
