<template>
  <el-dialog
    class="addDormRoomType-dialog"
    :title="`${preTitle}宿舍类型`"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="cancel"
    width="560px"
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
      <el-form-item label="宿舍类型" prop="roomTypeName">
        <el-input v-model.trim="formData.roomTypeName" @change="handleInput" />
      </el-form-item>
      <el-form-item label="可住人数" prop="peopleAmount">
        <el-select v-model="formData.peopleAmount">
          <el-option v-for="num in 10" :key="num" :value="num*2" :label="num*2" />
        </el-select>
      </el-form-item>
      <el-form-item label="单价" prop="price">
        <el-input v-model.trim="formData.price" >
          <template #append>元/年</template>
        </el-input>
      </el-form-item>
      <el-form-item label="宿舍布局图" prop="imgUrl">
        <single-img-uploader :url.sync="formData.imgUrl" width="100%" height="auto" />
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save('宿舍类型',addDormRoomType)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addDormRoomType, getDormRoomTypeById } from '@/api/dorm'
import dialog from '@/vue/mixins/dialog'
import { validateTwoDecimalPlaces } from '@/utils/form-validate'

export default {
  mixins: [dialog],
  data () {
    return {
      formData: {
        id: '',
        roomTypeName: '', // 宿舍类别 4人间
        price: '', // 价格
        peopleAmount: null, // 可住人数
        imgUrl: '', // 宿舍类型图
        remarks: ''
      },
      roomTypeList: [],
      rules: {
        roomTypeName: [{
          required: true,
          message: '请选择宿舍类型',
          trigger: 'blur'
        }],
        peopleAmount: [
          {
            required: true,
            message: '请填写可住人数',
            trigger: 'blur'
          },
          {
            min: 1,
            max: 20,
            type: 'number',
            message: '可住人数只能在1~20之间间',
            trigger: 'blur'
          }
        ],
        price: [
          {
            required: true,
            message: '请填写单价',
            trigger: 'blur'
          },
          {
            validator: validateTwoDecimalPlaces,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted () {
    this.$http.getParamListByKey('roomType', this, 'roomTypeList')
    this.$nextTick(() => {
      if (this.id) {
        this.getDataById(this.id, getDormRoomTypeById)
      }
    })
  },
  methods: {
    addDormRoomType, // 保存api
    // 选择宿舍类型
    changeDormType (roomType) {
      this.$nextTick(() => {
        let _roomType = ''
        if (roomType) {
          _roomType = this.roomTypeList.filter(item => item.paramName === roomType)[0].paramValue
        }
        this.formData.peopleAmount = _roomType
      })
    },
    // 获取可住人数
    handleInput (val) {
      if (val) {
        // 1找出第一个包含  x人间 的关键字
        const reg = /\d{1,2}人间/g
        // 2去掉'人间'
        const regNum = /\d+/g
        let _result = val.match(reg)
        if (_result?.length) {
          _result = _result[0].match(regNum)[0]
          // 3 这个数字必须小于20 ,否则触发表单校验
          this.formData.peopleAmount = Number(_result)
          // 单独触发校验
          this.$refs.form.validateField('peopleAmount')
        }
      }
    }
  }
}
</script>
<style lang="scss">
.addDormRoomType-dialog {
  .el-dialog {
    margin-top: 5vh !important;
  }
}
</style>
